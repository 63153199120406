import React from "react"
import { graphql, StaticQuery } from "gatsby"
import PromoPanel from "../promo-panel"

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark(
            filter: { fileAbsolutePath: { regex: "/(news-articles)/.*.md$/" } }
            sort: { fields: [frontmatter___articleDate], order: DESC }
            limit: 3
          ) {
            edges {
              node {
                frontmatter {
                  slug
                  title
                  image {
                    childImageSharp {
                      fixed(width: 125, height: 125) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                  articleDate(formatString: "MMMM YYYY")
                }
                excerpt
              }
            }
          }
        }
      `}
      render={data =>
        data.allMarkdownRemark.edges.map(data => (
          <PromoPanel
            key={data.node.frontmatter.slug}
            slug={`news/${data.node.frontmatter.slug}`}
            title={data.node.frontmatter.title}
            linkText="Read more &gt;"
          >
            <strong>{data.node.frontmatter.articleDate}</strong>
            <p>{data.node.excerpt}</p>
          </PromoPanel>
        ))
      }
    />
  )
}
