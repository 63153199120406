import React from "react"
import styled from "styled-components"

const Container = styled.div`
  margin: 15px auto 30px;

  @media (min-width: 960px) {
    display: flex;
    justify-content: space-between;
  }
`

export default ({ children }) => (
  <Container className="container container--padded cf">{children}</Container>
)
