import React from "react"
import { graphql } from "gatsby"
import Layout from "../layout"
import HomeHero from "../components/home-hero"
import LatestNews from "../components/latest-news"
import PromoContainer from "../components/promo-container"
import PromoPanel from "../components/promo-panel"
import styled from "styled-components"

const NewsHeading = styled.h2`
  border-top: 1px solid #cdcdcd;
  border-bottom: 1px solid #cdcdcd;
  padding: 10px 0;
  text-transform: uppercase;
  font-size: 1.3rem;
`

export default ({
  data: {
    markdownRemark: { frontmatter, html },
  },
}) => (
  <Layout>
    <HomeHero image={frontmatter.image} />
    <PromoContainer>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </PromoContainer>
    <PromoContainer>
      <PromoPanel
        slug="books"
        title="Books"
        image={frontmatter.promo1Image}
        linkText="View all books &gt;"
        isHeading={true}
      >
        <p>{frontmatter.promo1Text}</p>
      </PromoPanel>
      <PromoPanel
        slug="media"
        title="Media Work"
        image={frontmatter.promo2Image}
        linkText="View all media &gt;"
        isHeading={true}
      >
        <p>{frontmatter.promo2Text}</p>
      </PromoPanel>
      <PromoPanel
        slug="research-areas"
        title="Research Areas"
        image={frontmatter.promo3Image}
        linkText="View all research areas &gt;"
        isHeading={true}
      >
        <p>{frontmatter.promo3Text}</p>
      </PromoPanel>
    </PromoContainer>

    <div className="container container--padded cf">
      <NewsHeading>Latest News</NewsHeading>
    </div>

    <PromoContainer>
      <LatestNews />
    </PromoContainer>
  </Layout>
)

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        promo1Text
        promo1Image {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        promo2Text
        promo2Image {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        promo3Text
        promo3Image {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
