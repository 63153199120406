import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const HeroWrapper = styled.div`
  background-color: #8baabd;
  color: #fff;
  font-weight: bold;
  margin-bottom: 30px;

  @media (min-width: 620px) {
    font-size: 0.9em;
  }

  @media (min-width: 768px) {
    font-size: 1em;
  }
`

const Hero = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;

  @media (min-width: 620px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  @media (min-width: 960px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
`

const HeroImageFrame = styled.div`
  @media (min-width: 460px) {
    height: 580px;
    overflow: hidden;
  }

  @media (min-width: 620px) {
    float: left;
    width: 35%;
    height: auto;
    margin-right: 20px;
  }

  @media (min-width: 768px) {
    width: 270px;
  }

  @media (min-width: 960px) {
    margin: 0 30px 0 15px;
  }
`

const HeroImage = styled(Img)`
  width: 100%;
  margin: 0;
  display: block;
`

const HeroSubtitle = styled.span`
  display: block;
  line-height: 1;
  margin: 8px 0 0;
  font-size: 1.1rem;
  font-weight: normal;
`

const HeroBody = styled.p`
  margin-bottom: 0;
`

export default ({ image }) => (
  <HeroWrapper>
    <div className="container cf">
      <HeroImageFrame>
        <HeroImage
          sizes={{ ...image.childImageSharp.fluid, aspectRatio: 73 / 100 }}
        />
      </HeroImageFrame>
      <Hero className="container--padded">
        <h2 className="tf-body">
          Professor Geoffrey Beattie
          <HeroSubtitle>BSc PhD CPsychol CSci FBPsS FRSM FRSA</HeroSubtitle>
        </h2>
        <HeroBody>
          is an internationally acclaimed psychologist, author and broadcaster.
          He is Professor of Psychology at Edge Hill University and in recent
          years a Masters supervisor on the Sustainability Leadership Programme
          at the University of Cambridge and Visiting Professor at the
          University of California, Santa Barbara. He was Professor of
          Psychology at the University of Manchester from 1994-2012.
        </HeroBody>
      </Hero>
    </div>
  </HeroWrapper>
)
