import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const PromoPanel = styled.div`
  @media (min-width: 960px) {
    flex: 1;
    padding-right: 30px;
    font-size: 0.9rem;

    &:last-child {
      padding-right: 0;
    }
  }
`

const PromoHeading = styled.h2`
  ${({ mainPromo }) =>
    mainPromo === true &&
    `
    border-top: 1px solid #cdcdcd;
    border-bottom: 1px solid #cdcdcd;
    text-transform: uppercase;
  `}

  padding: 10px 0;
  font-size: 1.3rem;
`

const PromoImage = styled.div`
  width: 100%;
  margin-bottom: 20px;

  @media (min-width: 620px) {
    width: 40%;
    float: left;
    margin-right: 20px;
  }

  @media (min-width: 960px) {
    float: none;
    width: 100%;
  }
`

export default ({ children, slug, title, image, linkText, isHeading }) => (
  <PromoPanel className="cf">
    <PromoHeading mainPromo={isHeading}>{title}</PromoHeading>
    {image && (
      <PromoImage>
        <Img sizes={{ ...image.childImageSharp.fluid, aspectRatio: 16 / 12 }} />
      </PromoImage>
    )}
    {children}
    <p>
      <a href={`/${slug}/`} title={title}>
        {linkText}
      </a>
    </p>
  </PromoPanel>
)
